<!--
 * @Description: 生产商上传进度
 * @Author: zhang zhen
 * @Date: 2023-02-16 16:49:07
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-10-25 12:43:17
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/supplierUploadProgress.vue
-->

<template>
  <div class="UploadProgress">
    <div class="UploadProgress-left">
      <EmptyArea v-if="!info" style="width: 220px;height:150px" description="暂无发货单数据" />
      <template v-else>
        <div class="bill">
          <img
            :src="(info && info['invoiceFile']) || require('../../../../../assets/300x277.png')"
            class="cover"
            alt=""
            width="220px"
            height="277px"
          />
          <div class="cover-item">
            <div class="cover-item-left">
              <span class="name">货单号：{{ info && info.waybill }}</span>
              <span class="date">{{ info && info.lastModified }}</span>
            </div>
            <a-button type="danger" ghost @click="handleDownload(info['invoiceFile'])">
              下载
            </a-button>
          </div>
        </div>
      </template>
      <template v-if="orderStatus >= 2 && fileList2.length">
        <div class="bill">
          <img
            :src="fileList2[0]['url'] || require('../../../../../assets/300x277.png')"
            class="cover"
            alt=""
            width="220px"
            height="277px"
          />
          <div class="cover-item">
            <div class="cover-item-left">
              <span class="name">付款单</span>
              <span class="date">付款时间：{{ payTime }}</span>
              </div>
            <a-button type="danger" ghost @click="handleDownload(fileList2[0]['url'])">
              下载
            </a-button>
          </div>
        </div>
      </template>
      <EmptyArea v-else style="width: 220px;height:150px;margin-left: 8px;" description="暂无付款单数据" />
      <EmptyArea v-if="!info || !info['signFile']" style="width: 220px;height:150px;margin-left: 8px;" description="暂无收货单数据" />
      <template v-else>
        <div class="bill">
          <img
            :src="(info && info['signFile']) || require('../../../../../assets/300x277.png')"
            class="cover"
            alt=""
            width="220px"
            height="277px"
          />
          <div class="cover-item">
            <div class="cover-item-left">
              <span class="name">收货人姓名：{{ info && info.deliveryName }}</span>
              <span class="date">地址：{{ info && info.detailAddress }}</span>
            </div>
            <a-button type="danger" ghost @click="handleDownload(info['signFile'])">
              下载
            </a-button>
          </div>
        </div>
      </template>
    </div>
    <div class="btnArea">
      <a-upload
        name="file"
        :action="uploadAction"
        :headers="headers"
        :data="{ dir: 'images' }"
        :fileList="fileList"
        @change="handleChange"
        :disabled="orderStatus != '3'"
      >
        <a-button type="danger" ghost style="margin-bottom: 15px" :disabled="orderStatus != '3'">
          上传发货单
        </a-button>
      </a-upload>
      <a-button type="danger" ghost style="width: 102px" @click="handleAction" :disabled="orderStatus != '2'">
          进入生产
      </a-button>
    </div>
  </div>
</template>

<script>
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'supplierUploadProgress',
  components: {
    EmptyArea
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    orderStatus: {
      type: [String, Number],
      default: '1'
    }
  },
  data() {
    return {
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      fileList: [],
      fileList2: [],
      info: null,
      payTime: null
    }
  },
  created() {
    this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
  },
  methods: {
    handleLoadTransportInfo() {
      getAction('/order/transport', {
        orderNo: this.orderNo
      }).then(res => {
        const { success, message, data } = res
        if (success && data) {
          this.info = data
        }
      })
      getAction('/order/pay/get', {
        orderNo: this.orderNo
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { payFile, lastModified } = data
          this.fileList2 = [
            {
              url: payFile,
              srcFileName: '付款单',
              type: 'png',
              // uid: uidGenerator(),
              status: 'done',
              response: {}
            }
          ]
          this.payTime = lastModified
        }
      })
    },
    handleChange(info) {
      let fileList = info.fileList
      if (info.file.status === 'done') {
        fileList = fileList.splice(-1, 1)
        if (info.file.response.success) {
          fileList = fileList.map(res => {
            if (res.response && res.response.data) {
              const { url, filename, ext } = res.response.data
              res.url = url
              res.srcFileName = filename
              res.fileName = filename
              res.fileType = ext
            }
            return res
          })
          // console.log(fileList)
          // this.$message.success(`${info.file.name} 上传成功!`)
          this.handleUploadFiles(fileList[0]['url'])
        } else {
          this.$message.error(
            `${info.file.response.success ? info.file.response.message : '文件上传失败，不支持当前格式'}!`
          )
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败.`)
      }
      this.fileList = fileList
    },
    handleChange2(info) {
      let fileList = info.fileList
      if (info.file.status === 'done') {
        fileList = fileList.splice(-1, 1)
        if (info.file.response.success) {
          fileList = fileList.map(res => {
            if (res.response && res.response.data) {
              const { url, filename, ext } = res.response.data
              res.url = url
              res.srcFileName = filename
              res.fileName = filename
              res.fileType = ext
            }
            return res
          })
          // console.log(fileList)
          // this.$message.success(`${info.file.name} 上传成功!`)
          this.handleUploadConfirmFiles(fileList[0]['url'])
        } else {
          this.$message.error(
            `${info.file.response.success ? info.file.response.message : '文件上传失败，不支持当前格式'}!`
          )
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败.`)
      }
      this.fileList2 = fileList
    },
    handleUploadFiles(invoiceFile) {
      postAction('/order/transport/invoice', {
        orderNo: this.orderNo,
        invoiceFile
      }).then(res => {
        const { success, message, data } = res
        if (success) {
          // this.$message.success(message)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleUploadConfirmFiles(signFile) {
      postAction('/order/transport/file/confirm', {
        orderNo: this.orderNo,
        signFile
      }).then(res => {
        const { success, message, data } = res
        if (success) {
          // this.$message.success(message)
          debugger
          postAction('/order/transported', {
          orderNo: this.orderNo
        }).then(res => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            // this.handleLoadInfo()
          } else {
            this.$message.warning(message)
          }
        })
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleDownload(url) {
      if (!url) return this.$message.warning('没有单据信息')
      window.open(url)
    },
    /* 进入生产 */
    handleAction() {
      postAction('/order/producing', {
          orderNo: this.orderNo
        }).then(res => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            this.$emit('ok')
            this.handleLoadTransportInfo()
          } else {
            this.$message.warning(message)
          }
        })
    },
  }
}
</script>

<style lang="less" scoped>
.UploadProgress {
  .flexLayout(@alignItem: flex-start);
  padding-top: 30px;
  padding-right: 6px;
  &-left {
    .cover {
      width: 208px;
      &-item {
        margin-top: 15px;
        .flexLayout(@alignItem: flex-end);
        &-left {
          .flexLayout(@alignItem: flex-start);
          flex-direction: column;
          margin-right: 8px;
          .name {
            font-weight: bold;
            color: #333;
          }
          .date {
            color: #797979;
          }
        }
      }
    }
    .flexLayout(@alignItem: flex-start);
    flex-direction: row;
  }
  .bill {
    margin-right: 15px;
  }
  ::v-deep .ant-upload-list  {
    visibility: hidden;
  }
}
</style>
